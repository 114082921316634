<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">Users</div>
		</div>
	</div>
</template>

<script>
export default {
}
</script>
